var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    blue: _vm.isBlue,
    dark: _vm.isDark,
    light: _vm.isLight,
    pink: _vm.isPink,
  },attrs:{"id":"app"}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"main-nav-wrapper"},[_c('div',{staticClass:"navigation"},[_c('ul',_vm._l((_vm.navList),function(item){return _c('li',{key:item.id},[(item.children)?[_c('a',{ref:item.name,refInFor:true,staticClass:"bg1-hvr bg1-txt-hvr",attrs:{"href":"#","title":item.name},on:{"click":function($event){item.isOpen = !item.isOpen}}},[_vm._v(" "+_vm._s(item.name)+" "),_c('i',{ref:item.name + '-i',refInFor:true,staticClass:"fa fa-angle-down"})]),_c('div',{directives:[{name:"closable",rawName:"v-closable",value:({
                  excludeList: [item.name, item.name + '-i'],
                  handler: 'onClose',
                  uniqueFxnId: item.name,
                }),expression:"{\n                  excludeList: [item.name, item.name + '-i'],\n                  handler: 'onClose',\n                  uniqueFxnId: item.name,\n                }"}],staticClass:"dropdown",class:{ isOpen: item.isOpen }},[_c('ul',_vm._l((item.children),function({ path, name }){return _c('li',{key:name,on:{"click":function($event){return _vm.onClose()}}},[_c('router-link',{staticClass:"bg1-hvr bg1-txt-hvr",attrs:{"to":path}},[_vm._v(_vm._s(name))])],1)}),0)])]:[_c('router-link',{staticClass:"bg1-hvr bg1-txt-hvr",attrs:{"to":item.path}},[_vm._v(" "+_vm._s(item.name)+" ")])]],2)}),0)])]),_c('div',[_c('span',{staticClass:"icn",on:{"click":function($event){return _vm.setTheme('light')}}},[_vm._v("light")]),_vm._v(" | "),_c('span',{staticClass:"icn",on:{"click":function($event){return _vm.setTheme('dark')}}},[_vm._v("dark")]),_vm._v(" | "),_c('span',{staticClass:"icn",on:{"click":function($event){return _vm.setTheme('blue')}}},[_vm._v("blue")]),_vm._v(" | "),_c('span',{staticClass:"icn",on:{"click":function($event){return _vm.setTheme('pink')}}},[_vm._v("pink")])]),_c('router-view',{key:_vm.$route.path}),_c('footer-links')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }